import axios from 'axios'
import Config from './Config'
import router from '@/router/index'
import { Toast } from "vant";
import Common from "@/library/Common";
const common = Common;
axios.defaults.withCredentials = false;
axios.defaults.timeout = 180 * 1000
axios.defaults.baseURL = Config.backendUrl
// HTTPrequest拦截
axios.interceptors.request.use(config => {
    const isToken = (config.headers || {}).isToken === false
    let token = localStorage.getItem("token");
    if (token) {
        config.headers['Authorization'] = token
    }
    if (config.method === 'put') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    } else {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }

    return config
}, error => {
    return Promise.reject(error)
})
// HTTPresponse拦截
axios.interceptors.response.use(res => {
    let status = res.status
    if (status == 401) {
        Toast({
            forbidClick: true,
            message: '登录状态已过期，请重新登录\nLogin status has expired, please log in again',
        });
        common.removeLocal("token");
        common.removeLocal("phone_number");
        common.removeLocal("password");
        router.push("/login")

    }
    return res
}, error => {
    console.log('error', error.code, error.message, error.response);
    // 网络请求超时
    if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes('timeout')) {
        Toast({
            duration: 2000,
            forbidClick: true,
            message: '网络超时，请稍后再试！\nNetwork timeout, please try again later!',
        });
        // return Promise.reject(error)
        return 'timeout'
    } else {
        let status = error.response.status
        if (status == 401) {
            Toast({
                forbidClick: true,
                message: '登录状态已过期，请重新登录\nLogin status has expired, please log in again',
            });
            common.removeLocal("token");
            common.removeLocal("phone_number");
            common.removeLocal("password");
            router.push("/login")
        }
        return Promise.reject(new Error(error.response.data.detail))
    }

})
class MyRequest {
    constructor() {
        this.api = axios.create({
            baseURL: Config.backendUrl,
            withCredentials: false,
            timeout: 10 * 1000,
        });
        this.uploadApi = axios.create({
            baseURL: Config.uploadUrl,
            withCredentials: false,
            timeout: 300 * 1000,
            headers: {
                'apikey': Config.apikey,
                'Content-Type': 'application/json;charset=UTF-8',
            }
        });
    }


    getHeaders() {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
        }
        var token = localStorage.getItem("token");
        if (token) {
            headers['Authorization'] = token;
        }
        return headers;
    }
    async get(url) {
        return await axios.get(url);
    }
    async post(url, data) {
        return await axios.post(url, data);
    }
    async loginpost(url, data) {
        return await axios.post(url, data);
    }
    async put(url, data) {
        return await axios.put(url, data);
    }
    async del(url, data = {}) {
        return await axios.delete(url, {
            params: data
        });
    }
    async upload(data = {}) {
        return await this.uploadApi.post("", data);
    }
}
const myRequest = new MyRequest();
export default myRequest;